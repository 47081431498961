import React, {useContext, useEffect, useState} from 'react';
import './MusicRequests.scss';
import RequestHandlerContext from "../../../contexts/RequestHandler";
import {MusicRequest} from "../../../logic/objects";
import usePaginatedFetcher from "../../../logic/usePaginatedFetcher";
import {DeleteItem, Lister, ModifyItem} from "../../portable/lister/Lister";

/**
 * Lists and manages music requests.
 */
export const MusicRequests = () => {
    const requestHandler = useContext(RequestHandlerContext)
    const paginatedOptions = usePaginatedFetcher<MusicRequest>('/api/music/request/list?count=20&page=0')

    /**
     * Deletes the given music request through the API. If successful, [deleteLocal] is invoked with the supplied ID.
     * 
     * @param requestId   The ID of the music request to delete
     * @param deleteLocal The [DeleteItem] callback to remove the item from the UI
     */
    const deleteRequest = (requestId: string, deleteLocal: DeleteItem): Promise<void> => 
        requestHandler.request(`/api/music/request/${requestId}`, {method: 'DELETE'}).then(res => {
            if (res.status == 200) {
                deleteLocal(requestId)
            }
        })

    const displayRequest = (request: MusicRequest, modifyItem: ModifyItem<MusicRequest>, deleteItem: DeleteItem): JSX.Element => (
        <div className="list-group-item d-flex flex-row justify-content-between ps-md-4 pe-md-3">
            <div className="col-6">
                <span className="">{request.title}</span> - <span className="text-muted">{request.author}</span>
            </div>
            <div className="col d-flex flex-column">
                <span className="d-block text-end">{request.name}</span>
                <span className="d-block text-end">{request.email}</span>
            </div>
            <div className="delete d-flex flex-column justify-content-center ms-3 text-danger" onClick={() => deleteRequest(request.id, deleteItem)}>
                <span className="material-symbols-outlined inline-icon p-1 m-1">delete</span></div>
        </div>
    )

    return (
        <div className="MusicRequests">
            <h2 className="text-orange mb-3">Music Requests</h2>

            <div className="row">
                <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                    <Lister listClasses="list-group" includeParent={false} displayOptions={paginatedOptions} displayItem={displayRequest}/>
                </div>
            </div>
        </div>
    );
}
