import React, {ChangeEvent, Fragment, useCallback, useContext, useEffect, useState} from 'react';
import './ScheduleEdit.scss';
import {Calendar, Event, momentLocalizer, ToolbarProps, View} from 'react-big-calendar'
import {
    OccurrenceExclusionCreate,
    ShowOccurrence,
    ShowTimeslot,
    TimeslotCreate
} from "../../../logic/objects/timeslots";
import RequestHandlerContext from "../../../contexts/RequestHandler";

// @ts-ignore
import Toolbar from 'react-big-calendar/lib/Toolbar';
import {ShowDescription} from "../../../logic/objects";
import {classes} from "../../../logic/react_utility";
import moment from "moment";

const localizer = momentLocalizer(moment)

/**
 * A start and end date of an occurrence.
 */
type StartEndDate = { start: Date; end: Date }

/**
 * A simple component to invoke {@link ToolbarProps#onView} on initial loading of a {@link Toolbar}.
 *
 * @see <a href="https://github.com/jquense/react-big-calendar/issues/1752#issuecomment-761051235">Source</a>
 * @return The {@link Toolbar}
 */
const InitialRangeChangeToolbar = (props: ToolbarProps): Toolbar => {
    useEffect(() => {
        props.onView(props.view);
    }, []);
    return <Toolbar {...props} />;
}

/**
 * The page to edit shows' schedules.
 *
 * Shows are considered resources, with the ID being their show ID
 */
export const ScheduleEdit = () => {
    const requestHandler = useContext(RequestHandlerContext)
    const [occurrences, setOccurrences] = useState<ShowOccurrence[]>([])
    const [selectedOccurrence, setSelectedOccurrence] = useState<ShowOccurrence | undefined>()
    const [events, setEvents] = useState<OccurrenceCalendarEvent[]>([])
    const [shows, setShows] = useState<ShowDescription[]>([])
    const [date, setDate] = useState(new Date())

    // The dates previously used during date requesting
    const [previousDates, setPreviousDates] = useState<{ start?: Date, end?: Date }>({})

    /**
     * Sets the {@link occurrences}, {@link events}, and {@link shows} to be listed with start/end dates.
     *
     * @param start The inclusive start date
     * @param end   The inclusive end date
     */
    const requestWithNewDates = (start: Date, end: Date): void => {
        setPreviousDates({start: start, end: end})
        requestHandler.request(`/api/show/occurrence/list?startDate=${start.getTime()}&endDate=${end.getTime()}`)
            .then(async res => {
                let json = await res.json()
                let occurrences = json as ShowOccurrence[]
                setOccurrences(occurrences)
                setEvents(occurrences.map(occurrence => new OccurrenceCalendarEvent(occurrence)))

                // Gets unique shows by ID
                setShows(occurrences.map(occurrence => occurrence.show)
                    .filter((value, index, self) =>
                        self.findIndex((show) => show.id == value.id) == index))
            })
    }

    /**
     * Performs a refresh using {@link requestWithNewDates} using the previously invoked dates.
     */
    const requestWithPreviousDates = (): void => {
        const {start, end} = previousDates
        if (start != undefined && end != undefined) {
            requestWithNewDates(start, end)
        }
    }

    /**
     * Sets the new current date to base the calendar off of.
     */
    const onNavigate = useCallback((newDate) => setDate(newDate), [setDate])

    /**
     * Performs a refresh using {@link requestWithNewDates} upon a calendar range change. Range changes include going
     * to month, week, or day view.
     *
     * @param range The new range of the calendar
     * @param view  The {@link View} provided by the calendar
     */
    const onRangeChange = (range: Date[] | StartEndDate, view?: View): void => {
        let start: Date
        let end: Date

        if (!(range instanceof Array)) {
            range = range as StartEndDate
            start = range.start
            end = range.end
        } else {
            range = range as Date[]
            start = range[0]
            end = range[range.length - 1]
        }

        requestWithNewDates(start, end)
    }

    /**
     * Sets a {@link selectedOccurrence} when an occurrence is selected in the calendar.
     * @param event
     */
    const onSelectEvent = (event: OccurrenceCalendarEvent): void => {
        setSelectedOccurrence(event.occurrence)
    }

    /**
     * A wrapper to display a {@link OccurrenceCalendarEvent}.
     *
     * @param event    The event occurrence to display
     * @param children Any additional children provided by the calendar
     * @return The wrapper element
     */
    const eventWrapper = (event: OccurrenceCalendarEvent, children: any): JSX.Element => {
        return <div className="event-wrapper">{children}</div>;
    }

    return (
        <div className="ScheduleEdit d-flex flex-row row">
            <div className="col-12 col-lg-4 col-xxl-3">
                <InspectPane shows={shows} occurrences={occurrences} selectedOccurrence={selectedOccurrence} resetSelectedOccurrence={() => setSelectedOccurrence(undefined)} refreshCalendar={requestWithPreviousDates}/>
            </div>

            <div className="col-12 col-lg-8 col-xxl-9 calendar-wrapper">
                <Calendar
                    components={{
                        toolbar: InitialRangeChangeToolbar,
                        eventWrapper: ({event, children}) => eventWrapper(event, children)
                    }}
                    localizer={localizer}
                    date={date}
                    onNavigate={onNavigate}
                    onRangeChange={onRangeChange}
                    onSelectEvent={onSelectEvent}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    popup
                />
            </div>
        </div>
    );
}

/**
 * Properties for {@link InspectPane}.
 */
interface InspectPaneProps {
    /**
     * When this changes, the selectedShow will change
     */
    selectedOccurrence: ShowOccurrence | undefined

    /**
     * Resets the currently selected occurrence state, used for when shows/timeslots are changed in the inspect panel
     */
    resetSelectedOccurrence: () => void

    /**
     * Refreshes calendar
     *
     * TODO: Is it worth looking into manually making modifications to the calendar, to not cause a full refresh?
     */
    refreshCalendar: () => void

    /**
     * ALL shows in the currently displayed month
     */
    shows: ShowDescription[]

    /**
     * ALL occurrences in the currently displayed month
     */
    occurrences: ShowOccurrence[]
}

/**
 * The left pane of the calendar page to display information regarding a timeslot/occurrence, and to modify them.
 */
const InspectPane = (props: InspectPaneProps) => {
    const requestHandler = useContext(RequestHandlerContext)
    const [selectedShow, setSelectedShow] = useState<ShowDescription | undefined>()
    const [selectedExclusion, setSelectedExclusion] = useState<ShowOccurrence | undefined>()
    const [selectedTimeslot, setSelectedTimeslot] = useState<ShowTimeslot | undefined>()

    /**
     * Exclusions for the currently selected show and month.
     */
    const [exclusions, setExclusions] = useState<ShowOccurrence[]>([])

    /**
     * All timeslots for the currently selected show ({@link selectedShow}). This is not time-bound at all, so a
     * timeslot in this list may not even show up in the current month.
     */
    const [timeslots, setTimeslots] = useState<ShowTimeslot[]>([])

    /**
     * If the pane is creating a new timeslot.
     */
    const [addingTimeslot, setAddingTimeslot] = useState<boolean>(false)

    /**
     * If `true`, the next {@link InspectPaneProps#selectedOccurrence} will create an exclusion on the occurrence.
     */
    const [selectingOccurrenceToExclude, setSelectingOccurrenceToExclude] = useState<boolean>(false)

    /**
     * The selected day of the week characters that the currently selected timeslot ({@link selectedTimeslot}) occurs on
     */
    const [selectedDays, setSelectedDays] = useState<string[]>([])

    /**
     * Times below is the second offset from the beginning of the day each occurrence from the {@link selectedTimeslot}
     */
    const [startTime, setStartTime] = useState<number | undefined>()

    /**
     * Times below is the second offset from the end of the day each occurrence from the {@link selectedTimeslot}
     */
    const [endTime, setEndTime] = useState<number | undefined>()

    /**
     * The UTC millisecond time that falls on the beginning of the first week {@link selectedTimeslot} occurrences start
     */
    const [startDate, setStartDate] = useState<number | undefined>()

    /**
     * The UTC millisecond time that falls on the end of the last week {@link selectedTimeslot} occurrences end
     */
    const [endDate, setEndDate] = useState<number | undefined>()

    /**
     * Updates what show is selected and displayed on the panel. This sets {@link selectedShow} and requests its
     * timeslots.
     *
     * @param show The new selected show
     */
    const updateSelectedShow = (show: ShowDescription | undefined): Promise<ShowTimeslot[]> => {
        setSelectedShow(show)

        if (show == undefined) {
            setExclusions([])
            setTimeslots([])
            return Promise.all([])
        }

        // setSelectedTimeslot(undefined)
        // props.resetSelectedOccurrence()
        setExclusions(props.occurrences.filter(occurrence => occurrence.show.id == show?.id && occurrence.excluded))

        // TODO: Properly page this later
        return requestHandler.request(`/api/show/${show.id}/timeslot/list?count=20&page=0`)
            .then(async res => {
                let json = await res.json()
                let items = json.items // json is a paged object
                let timeslots = items as ShowTimeslot[]
                setTimeslots(timeslots)
                return timeslots
            })
    }

    /**
     * Updates editable properties when {@link selectedTimeslot} is updated.
     */
    useEffect(() => {
        setSelectedDays(selectedTimeslot?.days?.split('') ?? [])
        setStartTime(selectedTimeslot?.start)
        setEndTime(selectedTimeslot?.end)
        setStartDate(selectedTimeslot?.startDate)
        setEndDate(selectedTimeslot?.endDate)

        if (selectedTimeslot != undefined) {
            setAddingTimeslot(false)
        }
    }, [selectedTimeslot])

    /**
     * If the {@link addingTimeslot} changes to `true`, the {@link selectedTimeslot} is reset so that value can be
     * entered for the new timeslot.
     */
    useEffect(() => {
        if (addingTimeslot) {
            setSelectedTimeslot(undefined)
        }
    }, [addingTimeslot])

    /**
     * If the {@link selectingOccurrenceToExclude} is true, the next occurrence is being selected.
     */
    useEffect(() => {
        if (selectingOccurrenceToExclude && props.selectedOccurrence != undefined) {
            setSelectingOccurrenceToExclude(false)
            createExclusion(props.selectedOccurrence)
            // return
        }

        updateSelectedShow(props?.selectedOccurrence?.show).then(timeslots =>
            setSelectedTimeslot(timeslots.find(timeslot => timeslot.id == props.selectedOccurrence?.timeslot)))
    }, [props.selectedOccurrence])

    /**
     * Creates an exclusion for a single occurrence.
     *
     * @param occurrence The occurrence to create the exclusion for
     */
    const createExclusion = (occurrence: ShowOccurrence): void => {
        requestHandler.request(`/api/show/${occurrence.show.id}/occurrence/exclusion`, {
            method: 'POST', body: JSON.stringify({
                timeslot: occurrence.timeslot,
                occurrenceIndex: occurrence.index
            } as OccurrenceExclusionCreate)
        })
            .then(() => props.refreshCalendar())
    }

    /**
     * Invokes {@link updateSelectedShow} when the selected show changes from the dropdown.
     *
     * @param event The selection change event
     */
    const onChangeShow = (event: ChangeEvent<HTMLSelectElement>): void => {
        let showId = event.target.value
        updateSelectedShow(props.shows.find(show => show.id == showId))
            .then(timeslots => setSelectedTimeslot(undefined))
    }

    /**
     * Changes the {@link selectedTimeslot} when the selected timeslot changes.
     *
     * @param event The selection change event
     */
    const onChangeTimeslot = (event: ChangeEvent<HTMLSelectElement>): void => {
        let timeslotId = event.target.value
        setSelectedTimeslot(timeslots.find(timeslot => timeslot.id == timeslotId));
    }

    /**
     * Invokes the callback `setTimeFunction` with the day's second offset parsed from a `HH:mm` formatted date in the
     * input element. This is called upon change of the input element.
     *
     * @param event           The input change event
     * @param setTimeFunction The time callback
     */
    const onChangeTime = (event: ChangeEvent<HTMLInputElement>, setTimeFunction: (time: number | undefined) => void): void =>
        setTimeFunction(parseSeconds24Hr(event.target.value))

    /**
     * Invokes the callback `setDateFunction` with the day's second offset parsed from a `HH:mm` formatted date in the
     * input element. This is called upon change of the input element.
     *
     * @param event           The input change event
     * @param setDateFunction The time callback
     */
    const onChangeDate = (event: ChangeEvent<HTMLInputElement>, setDateFunction: (date: number | undefined) => void): void => {
        setDateFunction(parseDate(event.target.value))
    }

    /**
     * Gets a unique ID for an excluded {@link ShowOccurrence}. The format does not matter, it just needs to be unique
     * for the occurrence.
     *
     * @param exclusion The occurrence to get an ID of
     * @return A unique ID for th exclusion
     */
    const getExclusionShowId = (exclusion: ShowOccurrence | undefined): string => {
        if (exclusion == undefined) {
            return ''
        }

        return `${exclusion.show.id}-${exclusion.index}`
    }

    /**
     * Gets the display string of a {@link ShowTimeslot}.
     *
     * @param timeslot The timeslot to display
     * @return
     */
    const getTimeslotDisplay = (timeslot: ShowTimeslot | undefined): string => {
        if (timeslot == undefined) {
            return ''
        }

        return `${formatSeconds(timeslot.start)} - ${formatSeconds(timeslot.end)} ${timeslot.days}`
    }

    /**
     * Changes the {@link selectedExclusion} when the selected exclusion on the dropdown is selected. This may also be
     * undefined for unselecting an exclusion.
     *
     * @param event The selection change event
     */
    const onChangeExclusion = (event: ChangeEvent<HTMLSelectElement>): void => {
        let exclusionShowId = event.target.value
        if (exclusionShowId == undefined) {
            setSelectedExclusion(undefined)
            return
        }

        const [showId, index] = exclusionShowId.split('-')

        setSelectedExclusion(exclusions.find(exclusion => exclusion.show.id == showId && `${exclusion.index}` == index));
    }

    /**
     * Saves the current timeslot with the form data. If {@link addingTimeslot} is true, one is created. Otherwise, the
     * {@link selectedTimeslot} is modified.
     */
    const onSaveTimeslot = (): void => {
        if (selectedShow == undefined) {
            return
        }

        let url = ''

        if (addingTimeslot) {
            url = `/api/show/${selectedShow.id}/timeslot`
        } else {
            url = `/api/show/${selectedShow.id}/timeslot/${selectedTimeslot?.id}`
        }

        requestHandler.request(url, {
            method: 'POST', body: JSON.stringify({
                start: startTime,
                end: endTime,
                startDate: startDate,
                endDate: endDate,
                days: selectedDays.join('')
            } as TimeslotCreate)
        })
            .then(async res => {
                let json = await res.json()
                let created = json as ShowTimeslot
                props.refreshCalendar()
            })
    }

    /**
     * Deletes a timeslot {@link selectedTimeslot}.
     */
    const onDeleteTimeslot = (): void => {
        requestHandler.request(`/api/show/${selectedShow?.id}/timeslot/${selectedTimeslot?.id}`, {method: 'DELETE'})
            .then(res => {
                props.refreshCalendar()
            })
    }

    /**
     * A map with keys as their backend API week day character representations, mapped to the value of the first letter
     * of the day. For example, in the backend API, `Saturday` is represented by `U`, so the key/value pair would
     * be `'U': 'S'`
     *
     * Keys are used for lookup, while values are being displayed.
     */
    const days = Object.entries({'S': 'S', 'M': 'M', 'T': 'T', 'W': 'W', 'R': 'T', 'F': 'F', 'U': 'S'})

    /**
     * Creates an array of checkbox buttons (and labels) to represents the das of the week. The
     * {@link selectedTimeslot} will occur on every day selected.
     */
    const createDayButtons = (): JSX.Element[] => {
        return days.map(([inputDay, displayDay]) => {
            const onSelectDay = (event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                    setSelectedDays(old => [...old].concat(inputDay))
                } else {
                    setSelectedDays(old => old.filter(day => day != inputDay))
                }
            }

            return <>
                <input type="checkbox" className="btn-check" id={`day-${inputDay}`} autoComplete="off" checked={selectedDays.includes(inputDay)} onChange={onSelectDay} disabled={selectedTimeslot == undefined}/>
                <label className="btn btn-outline-secondary weekday" htmlFor={`day-${inputDay}`}>{displayDay}</label>
            </>
        })
    }

    /**
     * Toggles the {@link selectingOccurrenceToExclude}.
     */
    const toggleExcludingSelection = (): void => {
        setSelectingOccurrenceToExclude(old => !old)
    }

    return (
        <Fragment>
            <h3 className="text-orange">Manage Show</h3>

            <select className="form-select mt-3" aria-label="Default select example" value={selectedShow?.id} onChange={onChangeShow}>
                <option value=''>Select a show</option>
                {props.shows.map(show => <option key={show.id} value={show.id}>{show.name}</option>)}
            </select>

            <h5 className="mt-4">Timeslots</h5>

            <div className="input-group mb-3 mt-3">
                <select className="form-select" aria-label="Default select example" value={selectedTimeslot?.id} onChange={onChangeTimeslot} disabled={selectedShow == undefined}>
                    <option value=''>Select a timeslot</option>
                    {timeslots.map(timeslot =>
                        <option key={timeslot.id} value={timeslot.id}>{getTimeslotDisplay(timeslot)}</option>)}
                </select>

                <button className="btn btn-outline-secondary" type="button" disabled={selectedShow == undefined}>
                    <span className="material-symbols-outlined add">add</span></button>
            </div>

            <div className="input-group mb-3 col-12">
                {createDayButtons()}
            </div>

            <div className="row">
                <div className="col-6 col-xl-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="start-time-label">Start Time</span>
                        <input type="time" className="form-control" aria-describedby="start-time-label" disabled={selectedTimeslot == undefined} value={formatSeconds24Hr(startTime)} onChange={e => onChangeTime(e, setStartTime)}/>
                    </div>
                </div>

                <div className="col-6 col-xl-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="end-time-label">End Time</span>
                        <input type="time" className="form-control" aria-describedby="end-time-label" disabled={selectedTimeslot == undefined} value={formatSeconds24Hr(endTime)} onChange={e => onChangeTime(e, setEndTime)}/>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-6 col-xl-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="start-date-label">Start Occurrences</span>
                        <input type="date" className="form-control" aria-describedby="start-date-label" disabled={selectedTimeslot == undefined} value={formatDate(startDate)} onChange={e => onChangeDate(e, setStartDate)}/>
                    </div>
                </div>

                <div className="col-6 col-xl-12">
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="end-date-label">End Occurrences</span>
                        <input type="date" className="form-control" aria-describedby="end-date-label" disabled={selectedTimeslot == undefined} value={formatDate(endDate)} onChange={e => onChangeDate(e, setEndDate)}/>
                    </div>
                </div>
            </div>

            <div>
                <button type="button" className="btn btn-primary me-2" onClick={onSaveTimeslot} disabled={selectedTimeslot == undefined}>{addingTimeslot ? 'Add' : 'Save'}</button>
                <button type="button" className="btn btn-danger" onClick={onDeleteTimeslot} disabled={selectedTimeslot == undefined}>Delete</button>
            </div>

            <h5 className="mt-4">Exclusions</h5>

            <div className="input-group mb-3 mt-3">
                <select className="form-select" aria-label="Default select example" value={getExclusionShowId(selectedExclusion)} onChange={onChangeExclusion} disabled={selectedShow == undefined}>
                    <option value={undefined}>Select an exclusion</option>
                    {exclusions.map(exclusion => {
                        let exclusionShowId = getExclusionShowId(exclusion)
                        return <option key={exclusionShowId} value={exclusionShowId}>{exclusion.index}</option>;
                    })}
                </select>

                <button className="btn btn-outline-secondary" type="button" onClick={toggleExcludingSelection}>
                    <span className="material-symbols-outlined add">{selectingOccurrenceToExclude ? 'remove' : 'add'}</span>
                </button>
            </div>

            {selectingOccurrenceToExclude &&
                <p className="text-danger">Select the occurrence in the calendar to exclude</p>}

            <button type="button" className="btn btn-danger" disabled={selectedExclusion == undefined}>Delete</button>
        </Fragment>
    )
}

/**
 * Formats the given second offset to HH:mm AM/PM format.
 *
 * @param seconds The seconds from the beginning of the day
 */
function formatSeconds(seconds: number): string {
    return moment.utc(seconds * 1000).format("hh:mm a");
}

/**
 * Formats the given second offset to 24-hour HH:mm format.
 *
 * @param seconds The seconds from the beginning of the day
 * @return The HH:mm formatted time
 */
function formatSeconds24Hr(seconds: number | undefined): string {
    if (seconds == undefined) {
        return ''
    }

    return moment.utc(seconds * 1000).format("HH:mm")
}

/**
 * Parses HH:mm time into seconds from the beginning of the day.
 *
 * @param seconds The HH:mm time to parse
 * @return The seconds from the beginning of the day
 */
function parseSeconds24Hr(seconds: string | undefined): number | undefined {
    if (seconds == undefined) {
        return undefined
    }

    const [hours, minutes = 0] = seconds.split(':').map(num => parseInt(num))
    return (hours * 60 + minutes) * 60
}

/**
 * Formats the millisecond date to a YYYY-MM-DD format.
 *
 * @param msDate The millisecond time
 * @return The formatted date in YYY-MM-DD format
 */
function formatDate(msDate: number | undefined): string {
    if (msDate == undefined) {
        return ''
    }

    return moment(msDate).format("YYYY-MM-DD");
}

/**
 * Parses a YYY-MM-DD date into seconds from
 *
 * @param date The date to parse
 * @return The seconds from the beginning of the day
 */
function parseDate(date: string | undefined): number | undefined {
    if (date == undefined) {
        return undefined
    }

    return moment(date, "YYYY-MM-DD").valueOf()
}

/**
 * A single {@link ShowOccurrence} displayed on the calendar.
 */
class OccurrenceCalendarEvent implements Event {

    title: React.ReactNode
    start: Date
    end: Date
    occurrence: ShowOccurrence

    constructor(occurrence: ShowOccurrence) {
        this.title = createTitleElement(occurrence)
        this.start = new Date(occurrence.start)
        this.end = new Date(occurrence.end)
        this.occurrence = occurrence
    }
}

/**
 * Creates an element to display the title of a {@link ShowOccurrence}.
 *
 * @param occurrence The occurrence to display
 * @return The displayed occurrence
 */
function createTitleElement(occurrence: ShowOccurrence): JSX.Element {
    return <span className={classes(['excluded', occurrence.excluded])}>{occurrence.show.name}</span>
}
