import React, {createRef, useContext, useRef, useState} from 'react'
import './RequestBox.scss'
import '../Sidebar.scss'
import {Button} from "../../button/Button";
import useForm from "../../../logic/useForm";
import {RequestHandlerContext} from "../../../contexts/RequestHandler";
import {Result, statusToResult} from "../../../logic/requests";
import useReCaptchaNotice from "../../../logic/useReCaptchaNotice";
import {ReCaptchaNotice} from "../../portable/google/ReCaptchaNotice";

/**
 * The inputs that are mapped to the form by an input's name. These are for creating a music request.
 * This is initialized by {@link useForm}.
 */
type FormInputs = {
    /**
     * The name of the requester.
     */
    name: string

    /**
     * The email of the requester.
     */
    email: string

    /**
     * The title of the track.
     */
    title: string

    /**
     * The author of the track.
     */
    author: string
}

/**
 * A sidebar component that displays information regarding music requesting.
 */
export const RequestBox = () => {
    const requestHandler = useContext(RequestHandlerContext)
    const {noticeVisible, formAttributes} = useReCaptchaNotice()
    const [submitted, setSubmitted] = useState(Result.Waiting);
    const {handleSubmit, inputs} = useForm<FormInputs>()
    const formRef = useRef<HTMLFormElement>(null);

    /**
     * The minimum amount of milliseconds allowed between music requests (30 seconds).
     */
    const MIN_MILLISECONDS = 30 * 1000

    /**
     * Submits a music request.
     *
     * @param form The data of the submission
     * @returns `true` if the request was a success, `false` if otherwise
     */
    const submitRequest = (form: FormInputs): Promise<Result> => {
        if (!isWithinRateLimit()) {
            return Promise.resolve(Result.RateLimited)
        }

        return requestHandler.request('/api/music/request', {includeCaptcha: true, method: 'POST', body: JSON.stringify(form)})
            .then(res => statusToResult(res.status))
            .then(status => {
                if (status == Result.Success) {
                    clearInputs()
                }
                
                return status;
            })
    }

    /**
     * Submits the request, then sets {@link submitted}.
     *
     * @param inputs The form inputs of the page
     */
    const processSubmit = async (inputs: FormInputs) => submitRequest(inputs)
        .then(setSubmitted).then(() => {
            setTimeout(() => setSubmitted(Result.Waiting), 4000)
        })

    /**
     * Checks if the user is within their rate limiting bounds for music requests.
     */
    const isWithinRateLimit = (): boolean => {
        let lastRequest = localStorage.getItem('lastRequest')
        let now = Date.now()
        let time = parseInt(lastRequest ?? '0')

        if (now - time >= MIN_MILLISECONDS) {
            localStorage.setItem('lastRequest', now.toString())
            return true
        }

        return false
    }

    /**
     * Clears the inputs of the form.
     */
    const clearInputs = (): void => {
        formRef.current?.reset()
    }

    return (
        <div className="RequestBox sidebar-component">
            <h3 className="title">Request A Song</h3>
            <form ref={formRef} {...formAttributes} className="request-form" onSubmit={e => handleSubmit(e, processSubmit)}>
                <div className="mb-3 mt-2">
                    <label className="form-label mb-0" defaultValue={''}>Your Name</label>
                    <input type="text" name="name" className="form-control underline-input" required={true}/>
                </div>
                <div className="mb-3">
                    <label className="form-label mb-0">Your Email</label>
                    <input type="email" name="email" className="form-control underline-input mb-1" required={true}/>
                </div>
                <div className="mb-3">
                    <label className="form-label mb-0">Title</label>
                    <input type="text" name="title" className="form-control underline-input mb-1" required={true}/>
                </div>
                <div className="mb-1">
                    <label className="form-label mb-0">Artist</label>
                    <input type="text" name="author" className="form-control underline-input mb-1" required={true}/>
                </div>

                <Button submit={true} className="mt-2">Submit</Button>

                {submitted == Result.Success && <p className="text-success mt-3">
                    Submitted music request successfully!
                </p>}

                {submitted == Result.RateLimited && <p className="text-warning mt-3">
                    Slow down! Please don't spam music requests.
                </p>}

                {(submitted == Result.Unexpected || submitted == Result.InternalError) && <p className="text-danger mt-3">
                    There was an error while submitting your request
                </p>}

                <ReCaptchaNotice visible={noticeVisible} className="mt-3"/>
            </form>
        </div>
    )
}
