import React, {ChangeEvent, KeyboardEvent, useContext, useEffect, useState} from 'react';
import './TopSongsEdit.scss';
import RequestHandlerContext from "../../../contexts/RequestHandler";
import {BasicTrack, BasicTrackCreate} from "../../../logic/objects";
import {ArtworkDisplay} from "../../portable/artwork_display/ArtworkDisplay";

/**
 * The input data when adding a top song.
 */
interface TopSongInput {
    /**
     * The artist of the song.
     */
    artist: string

    /**
     * The title of the song.
     */
    track: string
}

/**
 * The page to edit the top songs on the homepage.
 */
export const TopSongsEdit = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The ordered {@link BasicTrack} list of top songs. This is the list of original display objects, and each element
     * correlates with an input in {@link topSongInputs}.
     */
    let [topSongs, setTopSongs] = useState<BasicTrack[]>([])

    /**
     * The ordered list of song inputs. Each element correlates with a display object in {@link topSongs}.
     */
    let [topSongInputs, setTopSongInputs] = useState<TopSongInput[]>([])

    /**
     * Performs an initial fetch of top songs.
     */
    useEffect(() => {
        refreshSongs()
    }, [])

    /**
     * Fetches all top songs and sets the appropriate {@link topSongInputs} an {@link topSongs}.
     */
     const refreshSongs = (): void => {
        requestHandler.request('/api/music/top').then(async res => {
            let json = await res.json()
            let tracks = json as BasicTrack[]
            setTopSongInputs(tracks.map(track => ({artist: track.artist, track: track.name} as TopSongInput)))
            setTopSongs(tracks)
        })
    }

    /**
     * Saves the top songs, matching the resulting {@link BasicTrackCreate.order} property with the position of the
     * song in the {@link topSongs} list.
     */
    const saveSongs = (): void => {
        requestHandler.request('/api/music/top', {method: 'POST', body: JSON.stringify(topSongInputs.map((input, i) => ({
                name: input.track,
                artist: input.artist,
                image: '',
                order: i
            } as BasicTrackCreate)))}).then(async res => {
            let json = await res.json()
            setTopSongs(json as BasicTrack[])
        })
    }

    /**
     * Updates the name for an indexed song.
     *
     * @param event The {@link HTMLInputElement} change event
     * @param index The index of the song
     */
    const updateTrackInput = (event: ChangeEvent<HTMLInputElement>, index: number): void => {
        setTopSongInputs(old => {
            let newInputs = [...old]
            newInputs[index].track = event.target.value
            return newInputs
        })
    }

    /**
     * Updates the artist for an indexed song.
     *
     * @param event The {@link HTMLInputElement} change event
     * @param index The index of the song
     */
    const updateArtistInput = (event: ChangeEvent<HTMLInputElement>, index: number): void => {
        setTopSongInputs(old => {
            let newInputs = [...old]
            newInputs[index].artist = event.target.value
            return newInputs
        })
    }

    /**
     * Saves songs via {@link saveSongs} when a {@link KeyboardEvent} receives the key of `Enter`.
     *
     * @param e The keyboard event
     */
    const onInputKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
        if (e.key == 'Enter') {
            saveSongs()
        }
    }

    return (
        <div className="TopSongsEdit">
            <h3 className="text-orange mb-3">Edit Top Songs</h3>

            <div className="row">
                <div className="col-12 col-lg-6">
                    <h4>Edit</h4>

                    {topSongs.map((track, i) => {
                        let songValue = topSongInputs[i]
                        return (
                            <div className="input-group mb-4" onKeyDown={onInputKeyDown}>
                                <span className="input-group-text">#{i + 1} Track</span>
                                <input type="text" placeholder="Track Name" className="form-control" value={songValue.track} onChange={e => updateTrackInput(e, i)}/>
                                <input type="text" placeholder="Artist" className="form-control" value={songValue.artist} onChange={e => updateArtistInput(e, i)}/>
                            </div>
                        )
                    })}

                    <button type="button" className="btn btn-primary me-2" onClick={saveSongs}>Save</button>
                </div>
                <div className="col-12 col-lg-6">
                    <h4>Preview</h4>
                    {topSongs.map(track =>
                        <ArtworkDisplay alt="Artwork" className="mb-3" key={track.id} image={track.image} primaryText={track.name} secondaryText={track.artist}/>
                    )}
                </div>
            </div>
        </div>
    )
}
