import {classes, State} from "../../../logic/react_utility";
import React, {Fragment, useEffect, useState} from "react";
import useForm from "../../../logic/useForm";
import {useIsFirstRender} from "../../../logic/useIsFirstRender";

/**
 * A rendered component that specifies the classes of its parent wrapper.
 */
export type Renderer = {

    /**
     * The classes to apply to the parent.
     */
    parentClasses: string

    /**
     * The element to render.,
     */
    element: JSX.Element
}

/**
 * Public properties for all modal types. Additional properties may be included for specific modal types.
 */
export interface ModalProps {

    /**
     * The {@link State} for if the modal should be visible or not.
     */
    showState: State<boolean>

    /**
     * Optional classes to add to the root element of the modal.
     */
    className?: string | undefined

    /**
     * A function invoked after the final modal transition ends to hide it.
     */
    hideModalHook?: (() => void) | undefined

    /**
     * A function to render the modal's header.
     */
    header: (hideModal: () => void) => JSX.Element | Renderer

    /**
     * A function to render the modal's body.
     */
    body: (hideModal: () => void) => JSX.Element | Renderer

    /**
     * A function to render the modal's footer.
     */
    footer: (hideModal: () => void) => JSX.Element | Renderer
}

/**
 * Properties used internally to render flexible modals.
 */
interface ModalRendererProps {

    /**
     * Wraps everything (head, body and footer) in a single parent.
     *
     * @param content A fragment of the modal's content
     */
    modalContentWrapper: (content: JSX.Element) => JSX.Element
}

/**
 * A component to display a modal of content.
 */
const ModalRenderer = ({
                           showState: [externalShow, setExternalShow],
                           className,
                           header,
                           body,
                           footer,
                           modalContentWrapper,
                           hideModalHook
                       }: ModalRendererProps & ModalProps) => {

    /**
     * Checks if the component is currently in its first rendering, to skip initial value of {@link showState}.
     */
    const isFirstRender = useIsFirstRender()

    /**
     * Similar to {@link show}, this is toggled just after {@link show} is `true` and just before {@link show} is
     * `false` to allow for CSS animations to occur.
     */
    const [transitionedShow, setTransitionedShow] = useState<boolean>(false)

    /**
     * The internal show state, abstracted from {@link showState} to deal with transitions better.
     */
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        if (externalShow) {
            setShow(true)
        } else if (!isFirstRender) {
            hideModal()
        }
    }, [externalShow])

    useEffect(() => {
        if (show) {
            setTimeout(() => setTransitionedShow(true), 50)
        }
    }, [show])

    /**
     * Shows a model closing animation, and then sets {@link show} to `false` when it is complete. This action resets
     * the {@link form}'s fields.
     */
    const hideModal = (): void => {
        setTransitionedShow(false)
        setTimeout(() => {
            setShow(false)
            setExternalShow(false)
            hideModalHook?.call(this)
        }, 150) // ms the bootstrap transition takes
    }

    /**
     * Renders part of the modal, given by the component properties. This may either be a {@link Renderer} that
     * supplied both an element and classes to add onto the parent div being created, or a direct {@link JSX.Element}
     * which implies no additional classes are added to the parent.
     *
     * @param obj          The rendering object
     * @param wrapperClass The class of the parent div
     */
    const renderPart = (obj: JSX.Element | Renderer, wrapperClass: string): JSX.Element => {
        let element: JSX.Element
        let combinedClasses = wrapperClass

        if (obj.hasOwnProperty('element')) {
            let renderer = obj as Renderer
            combinedClasses += ` ${renderer.parentClasses}`
            element = renderer.element
        } else {
            element = obj as JSX.Element
        }

        return <div className={combinedClasses}>{element}</div>
    }

    return (<>
        <div className={classes(className, 'modal fade', ['show', transitionedShow])} style={{display: show ? 'block' : 'none'}} aria-labelledby="exampleModalLabel" aria-hidden="true" onClick={() => {}}>
            <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                {modalContentWrapper(<>
                    {renderPart(header(hideModal), 'modal-header')}
                    {renderPart(body(hideModal), 'modal-body')}
                    {renderPart(footer(hideModal), 'modal-footer')}
                </>)}
            </div>
        </div>

        {show &&
            <div className={classes('modal-backdrop fade', ['show', transitionedShow])} onClick={() => {}}></div>
            // <div className={classes('modal-backdrop fade', ['show', transitionedShow])} onClick={() => hideModal()}></div>
        }
    </>)
}

/**
 * A basic modal component that has configurable content.
 */
export const Modal = (modalProps: ModalProps) => <ModalRenderer {...modalProps} modalContentWrapper={child =>
    <div className="modal-content">{child}</div>}/>

/**
 * Properties specific to form modals. `T` is the type containing form fields, the same as what is used with
 * {@link useForm}.
 */
interface FormModalProps<T> {

    /**
     * Processes the form submit action, with all inputs automatically fetched and placed into an instance of {@link T}.
     *
     * @param inputs The form data being submitted
     */
    processSubmit: (inputs: T) => Promise<void>
}

/**
 * A modal that has a wrapping `<form>` element that can process submissions.
 */
export const FormModal = <T extends unknown>(props: FormModalProps<T> & ModalProps) => {
    /**
     * The form HTML element.
     */
    const [form, setForm] = useState<HTMLFormElement | null>(null)

    /**
     * A hook for processing form data.
     */
    const {handleSubmit} = useForm<T>()

    const {processSubmit, ...rendererProps} = props

    return <ModalRenderer {...rendererProps} hideModalHook={() => form?.reset()} modalContentWrapper={content => (
        <form ref={setForm} className="modal-content" onSubmit={e => handleSubmit(e, processSubmit)} autoComplete="off">
            {content}
        </form>
    )}/>
}
