/**
 * A permission role a user may have.
 */
export class Role {
    static developer: Role = new Role('developer', 3);
    static engineer: Role = new Role('engineer', 2);
    static staff: Role = new Role('staff', 1);
    static dj: Role = new Role('dj', 0);

    static values: Role[] = [Role.developer, Role.engineer, Role.staff, Role.dj]

    /**
     * The name of the role, lining up with the backend's names.
     */
    name: string

    /**
     * The hierarchy of roles, higher meaning more powerful.
     */
    level: number

    constructor(name: string, level: number) {
        this.name = name;
        this.level = level;
    }

    /**
     * Gets a {@link Role} by its {@link name}.
     *
     * @param name The name of the role
     * @return The found role
     */
    static fromName(name: string): Role {
        return Role.values.find(role => role.name == name)!
    }
}

/**
 * A representation of the currently authenticated user.
 */
export class Self {
    /**
     * A unique ID of the user.
     */
    id: string

    /**
     * The user's email.
     */
    email: string

    /**
     * The user's first name.
     */
    firstName: string

    /**
     * The user's last name.
     */
    lastName: string

    /**
     * The roles the user has.
     */
    roles: Role[]

    /**
     * If the user has 2FA enabled.
     */
    mfa: boolean

    constructor(json: any) {
        this.id = json['id'];
        this.email = json['email'];
        this.firstName = json['firstName'];
        this.lastName = json['lastName'];
        this.roles = json['roles'].map(Role.fromName)
        this.mfa = json['mfa'];
    }

    /**
     * Checks if the user has at least the {@link Role.staff} role.
     *
     * @return If the user has administrator-level permissions.
     */
    isAdmin(): boolean {
        return this.isAtLeast(Role.staff)
    }

    /**
     * Checks if the user has at least the given role.
     *
     * @param role The role to check
     * @return If the user has the role or above
     */
    isAtLeast(role: Role): boolean {
        return this.roles.find(userRole => userRole.level >= role.level) != undefined
    }

    /**
     * Converts the {@link Self} to a JSON string, to be parsed and read through the constructor.
     */
    toJson(): any {
        return JSON.stringify({
            id: this.id,
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            roles: this.roles.map(role => role.name),
            mfa: this.mfa,
        })
    }
}
