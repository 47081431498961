import {ChangeEvent, FormEvent, useState} from 'react';

/**
 * Supported input element types.
 */
type Supported = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

/**
 * A hook used for managing form inputs. Type T is a type of all the types available in the form, with the name of
 * each field corresponding with the name of an input.
 */
export default function useForm<T>() {
    const [inputs, setInputs] = useState({} as T);

    /**
     * Updates the inputs state for a given target input of type Supported
     * @param target The target element
     * @returns The key/value added to inputs, key being the name of the target element, and value being its value
     */
    const updateInputs = (target: Supported): T => {
        const type = target.type;
        let name = target.name;
        let value: any
        if (target instanceof HTMLInputElement) {
            if (type == 'checkbox') {
                value = (target as HTMLInputElement).checked
            } else {
                value = target.value
            }
        } if (target instanceof HTMLTextAreaElement) {
            value = target.value
        } else if (target instanceof HTMLSelectElement) {
            value = [...target.options].filter(o => o.selected).map(o => o.value)

            if (!target.multiple && value.length > 0) {
                value = value[0]
            }
        }

        let t = {[name]: value} as T
        setInputs(values => ({...values, ...t}))
        return t
    }

    /**
     * Invoked when a form is submitted, this collects all named form inputs and processes them into a data type T, and
     * returns them when a callback is invoked. If this is not done, unmodified inputs would not be in the state. Data
     * is sent through a callback because the state object may not actually be updated when the callback is invoked.
     * @param event The form submission event
     * @param callback The callback with the form data
     */
    const handleSubmit = async (event: FormEvent<HTMLFormElement>, callback: (data: T) => Promise<void>) => {
        event.preventDefault();

        let data = [...event.currentTarget.querySelectorAll('[name]:not([name=""])')]
            .filter(e => e instanceof HTMLInputElement || e instanceof HTMLTextAreaElement || e instanceof HTMLSelectElement)
            .flatMap(e => updateInputs(e as Supported))
            .reduce((a, b) => ({...a, ...b}))

        await callback(data)
    }

    return {
        handleChange: (e: ChangeEvent<Supported>) => updateInputs(e.target),
        handleSubmit: handleSubmit,
        inputs
    }
}
