import React from 'react';
import './Sidebar.scss';
import {TopSongs} from "./top_songs/TopSongs";
import {RequestBox} from "./request_box/RequestBox";
import {OnAir} from "./on_air/OnAir";
import {AskMunson} from "./ask_munson/AskMunson";

/**
 * The properties for {@link Sidebar}.
 */
interface SidebarProps {
    /**
     * Any additional classes to add to the sidebar.
     */
    className?: String
}

/**
 * A component representing the sidebar of the website. This goes below the content on smaller screens.
 */
export const Sidebar = (props: SidebarProps) => {
    return (
        <div className={'Sidebar ' + (props.className ?? '')}>
            <OnAir/>
            {/*<AskMunson/>*/}
            <TopSongs/>
            <RequestBox/>
        </div>
    );
}
