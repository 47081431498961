import React, {Fragment, useContext, useEffect, useState} from 'react';
import './InviteModal.scss';
import RequestHandlerContext from "../../../../contexts/RequestHandler";
import {capitalizeFirst} from "../../../../logic/utility";
import {Role} from "../../../../logic/self";
import useForm from "../../../../logic/useForm";
import {ToastHandlerContext, ToastInfo} from "../../../../contexts/ToastHandler";
import {classes, State} from "../../../../logic/react_utility";
import {FormModal, Renderer} from "../../../portable/modal/Modal";

/**
 * The inputs that are mapped to the form by an input's name. These are for setting initial user details.
 * This is initialized by {@link useForm}.
 */
type FormInputs = {
    /**
     * The user's first name.
     */
    firstName: string

    /**
     * The user's last name.
     */
    lastName: string

    /**
     * The user's email.
     */
    email: string

    /**
     * The roles the user has. These are used as permissions.
     */
    roles: string[]

    /**
     * If the registration email should be delayed (to an administrator-decided time in the future).
     */
    delayEmail: boolean
}

/**
 * The properties for {@link InviteModal}
 */
interface InviteModalProps {
    /**
     * The {@link State} for if the modal should be visible or not.
     */
    showState: State<boolean>
}

/**
 * A popup modal to begin a {@link User}'s registration.
 */
export const InviteModal = ({showState}: InviteModalProps) => {
    const requestHandler = useContext(RequestHandlerContext)
    const toastHandler = useContext(ToastHandlerContext)

    /**
     * Starts the registration of a {@link User} based on its given form inputs. This is invoked as a callback to
     * {@link handleSubmit}.
     *
     * @param inputs The form data corresponding to inputs with the same name as each field
     */
    const processSubmit = async (inputs: FormInputs): Promise<void> => {
        requestHandler.request('/api/auth/registration/start', {method: 'POST', body: JSON.stringify(inputs)})
            .then(res => {
                if (res.status == 200) {
                    let firstName = inputs.firstName
                    if (inputs.delayEmail) {
                        toastHandler.addToast(new ToastInfo(`Created ${firstName}`, `Successfully created a user entry for ${inputs.firstName}. A registration email has not been sent yet.`))
                    } else {
                        toastHandler.addToast(new ToastInfo(`Invited ${firstName}`, `Successfully sent ${inputs.firstName} a registration email`))
                    }
                } else {
                    toastHandler.addToast(new ToastInfo(`An error occurred`, `An error occurred while creating a user. Status code: ${res.status}`, ToastInfo.COLOR_RED))
                }
            })
    }

    return (
        <FormModal processSubmit={processSubmit} showState={showState} header={hideModal => <>
            <h5 className="modal-title" id="exampleModalLabel">Invite user to WITR</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => hideModal()}></button>
        </>} body={() => <>
            <div className="mb-4">
                <input type="text" name="firstName" placeholder="First Name" className="form-control underline-input"/>
            </div>

            <div className="mb-4">
                <input type="text" name="lastName" placeholder="Last Name" className="form-control underline-input"/>
            </div>

            <div className="mb-4">
                <input type="email" name="email" placeholder="Email" className="form-control underline-input"/>
            </div>

            <label htmlFor="roles" className="mb-1">Roles</label>
            <select id="roles" className="form-select" name="roles" multiple aria-label="Select roles">
                {Role.values.map(role =>
                    <option value={role.name} key={role.name}>{capitalizeFirst(role.name)}</option>)}
            </select>
        </>} footer={hideModal => <>
            <div className="form-check me-auto">
                <input className="form-check-input" type="checkbox" value="" id="delayEmail" name="delayEmail"/>
                <label className="form-check-label" htmlFor="delayEmail">
                    Delay email
                </label>
            </div>

            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => hideModal()}>Cancel</button>
            <button type="submit" className="btn btn-primary">Invite user</button>
        </>}/>
    )
}
