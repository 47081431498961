import React, {CSSProperties} from 'react';
import './About.scss';

/**
 * The about page of the website, showing general information about the station.
 */
export const About = () => {
    return (
        <div className="About d-flex flex-row justify-content-center">
            <div className="content-wrapper d-flex row flex-column col-12 col-lg-11 col-xxl-10">
                <div className="row order-0 d-flex flex-column flex-md-row">
                    <div className="col-12 col-md-8 mt-2">
                        <h3>Welcome to WITR</h3>
                        <p>WITR 89.7 is a collaborative radio station run by students with a love of music and a desire
                            to
                            foster a community that shares in this passion. WITR is the authoritative source of new and
                            undiscovered music, providing the public with a unique media experience and serving as a
                            public
                            face of the Rochester Institute of Technology.</p>
                        <br/>
                        <p>WITR is fueled by passion. Our students pride themselves on being a progressive and evolving
                            station that constantly pushes the boundaries of music and extends its potential as an
                            organization.</p>

                        <h3 className="mt-3 mt-lg-5">The Music</h3>
                        <p>WITR strives to be the best student-run and operated radio station in the country. Our DJs
                            are exceptional at what they do, seeking out talented, emerging artists and providing
                            listeners with a unique listening experience across all genres. WITR is your source for new
                            and upcoming music.</p>
                    </div>
                    <div className="div-image align-self-center align-self-md-auto col-6 col-md-4 mt-3 mt-md-0 mb-4 mb-md-0" style={{'--image': 'url(/images/static/bw_zaff.jpg)'} as CSSProperties}></div>
                </div>

                <div className="row mt-3 mt-lg-5">
                    <div className="col-12 col-lg-6 order-lg-1">
                        <h3 className="margin-top">Community & Culture</h3>
                        <p>WITR is a family of hard-working and diverse students. It breeds leaders with strong
                            character who seek to meaningfully impact the world around them. Unified by their love of
                            the station, our members form deep bonds and create a mutually supportive environment of
                            self-expression and personal growth.</p>
                    </div>

                    <img className="col-12 col-lg-4 mt-3 mt-lg-0" src="/images/static/college_radio_day.jpg" alt="College Radio Day"/>
                </div>

                <div className="col-12 order-5 order-lg-5 mt-3 mt-lg-5">
                    <h3 className="margin-top">Governing Documents</h3>
                    <p>The primary governing documents of WITR are the Bylaws, Policies & Procedures, and All Member
                        Manual. The All Member Manual is located <a href="/all_member_manual.pdf">here</a>, with the other documents being worked on.</p>
                    
                    <h3 className="margin-top mt-3">Sponsors</h3>
                    <img className="col-12 col-md-4 mt-3 mt-md-0" src="/images/static/dd_logo_h_rgb.svg" alt="Datadog"/>
                </div>
            </div>
        </div>
    );
}
