import React, {createContext, useState} from "react";
import {classes, State} from "../logic/react_utility";

/**
 * A handler for sending toasts/notifications in the corner of the stream.
 */
export class ToastHandler {

    /**
     * The currently displayed toasts.
     */
    readonly toastInfos: ToastInfo[]

    /**
     * Sets the {@link toastInfos}.
     */
    private readonly setToastInfos: React.Dispatch<React.SetStateAction<ToastInfo[]>>

    /**
     * The time in MS a toast should last
     * @private
     */
    private readonly toastTimeout: number

    constructor(toastInfos: State<ToastInfo[]>, toastTimeout: number) {
        [this.toastInfos, this.setToastInfos] = toastInfos;
        this.toastTimeout = toastTimeout
    }

    /**
     * Displays a toast for a certain amount of time. This adds onto whatever other toasts are displayed, and will
     * disappear independently of everything else.
     *
     * @param toastInfo The toast to display
     */
    addToast(toastInfo: ToastInfo): void {
        this.setToastInfos(old => [toastInfo, ...old])

        setTimeout(() => {
            this.setToastShow(toastInfo, true)
        }, 100)

        setTimeout(() => {
            this.setToastShow(toastInfo, false)
        }, this.toastTimeout - 200)

        setTimeout(() => {
            this.setToastInfos(old => old.slice(0, -1))
        }, this.toastTimeout)
    }

    /**
     * Shows or hides a toast, with a fading animation.
     *
     * @param toastInfo The toast to show
     * @param show      If the toast should be shown or not
     */
    setToastShow(toastInfo: ToastInfo, show: boolean): void {
        this.setToastInfos(old => {
            let index = old.findIndex(oldInfo => oldInfo.id == toastInfo.id)
            let info = old[index]
            info.show = show

            let newArray = [...old]
            newArray[index] = info
            return newArray
        })
    }
}

/**
 * A toast that can be displayed.
 */
export class ToastInfo {
    /**
     * A unique ID of the toast.
     */
    id: number

    /**
     * The title of the toast.
     */
    title: string

    /**
     * The text content of the toast.
     */
    text: string

    /**
     * The show state of the toast. When toggled, this activates a fading animation.
     */
    show: boolean

    /**
     * The color hex of the toast icon.
     */
    color: string

    /**
     * The default bootstrap `.bg-success` class value.
     */
    static readonly COLOR_GREEN: string = '198754'

    /**
     * The default bootstrap `.bg-danger` class value.
     */
    static readonly COLOR_RED: string = 'dc3546'

    /**
     * Creates a hidden ToastInfo with given text.
     *
     * @param title The title of the toast
     * @param text The text to include in the toast
     * @param color The color in hex of the toast icon, without the #. Default is the success green color
     */
    constructor(title: string, text: string, color: string = ToastInfo.COLOR_GREEN) {
        this.id = new Date().getTime()
        this.title = title
        this.text = text
        this.color = color
        this.show = false
    }
}

/**
 * A context to provide a singleton {@link ToastHandler}.
 */
export const ToastHandlerContext = createContext<ToastHandler>({} as ToastHandler)

/**
 * The properties for {@link ToastHandlerProvider}.
 */
interface ToastHandlerProviderProps {
    /**
     * The children of the provider.
     */
    children: JSX.Element
}

/**
 * A component returning a provider to provide a singleton {@link ToastHandler}.
 */
export const ToastHandlerProvider = ({children}: ToastHandlerProviderProps) => {

    /**
     * The toasts being displayed.
     */
    const toastInfos = useState<ToastInfo[]>([])

    /**
     * The single toast handler to handle all toasts.
     */
    const [toastHandler] = useState<ToastHandler>(() => new ToastHandler(toastInfos, 4000))

    return (
        <ToastHandlerContext.Provider value={toastHandler}>
            {children}

            <div className="toast-container position-fixed bottom-0 end-0 p-3">
                {toastInfos[0].map(toastInfo => {
                    return (
                        <div className={classes('toast align-items-center fade show', ['showing', !toastInfo.show])} role="alert" aria-live="assertive" aria-atomic="true">
                            <div className="toast-header">
                                <div className="rounded me-2 color-block" style={{backgroundColor: `#${toastInfo.color}`}}/>
                                <strong className="me-auto">{toastInfo.title}</strong>
                                {/*<small>11 mins ago</small>*/}
                                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                            </div>
                            <div className="toast-body">
                                {toastInfo.text}
                            </div>
                        </div>
                    )
                })}
            </div>
        </ToastHandlerContext.Provider>
    )
}
