import React from 'react';
import {ShowDescription} from "../../../logic/objects";
import {AddModalOptions, DeleteItem, Lister, ModifyItem} from "../../portable/lister/Lister";
import usePaginatedFetcher from "../../../logic/usePaginatedFetcher";
import {ShowCard} from "./show_card/ShowCard";
import {AddStaffModal} from "../staff_manage/add_staff/AddStaff";
import {CreateShowModal} from "./create_show_modal/CreateShowModal";

/**
 * A list of all shows, to edit/delete them.
 */
export const ManageShows = () => {

    const paginatedOptions = usePaginatedFetcher<ShowDescription>('/api/show/list?count=20&page=0')

    const addModalOptions: AddModalOptions<ShowDescription> = {
        buttonText: 'Create Show',
        modal: (visibleState, modifyItem) => <CreateShowModal showState={visibleState} addShow={modifyItem}/>
    }

    const displayItem = (item: ShowDescription, modifyItem: ModifyItem<ShowDescription>, deleteItem: DeleteItem): JSX.Element => (
        <ShowCard show={item} deleteLocalShow={() => deleteItem(item.id)}/>
    )

    return (
        <div className="Shows">
            <h3 className="text-orange mb-3">Manage Shows</h3>

            <Lister displayOptions={paginatedOptions} displayItem={displayItem} addModalOptions={addModalOptions}/>
        </div>
    )
}
