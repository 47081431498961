import React from 'react';
import './ListingComponent.scss';
import {classes} from "../../../logic/react_utility";

/**
 * An item that can be listed in the {@link ListingComponent}.
 */
export interface ListingItem {
    /**
     * Some unique string to identify the item.
     */
    key: string

    /**
     * The image being displayed with the item.
     */
    image: string

    /**
     * The title of the item.
     */
    title: string

    /**
     * The subtitle of the item.
     */
    subtitle: string
}

/**
 * The properties for {@link ListingComponent}.
 */
interface ListingComponentProps {
    /**
     * The heading of the listing
     */
    title: string

    /**
     * The image aspect ratio
     */
    aspectRatio: number

    /**
     * The width of the item in pixels
     */
    itemWidth?: number

    /**
     * If no `itemWidth` is specified this MUST be given. This is the class added to the divs with the `items` and
     * `item` classes to control the grid/sizing of items.
     */
    controllingClass?: string

    /**
     * The components to display
     */
    items: ListingItem[]
}

/**
 * A component that lists items in a responsive grid. Each item has an item and two pieces of text, similar to a very
 * large {@link ArtworkDisplay}.
 */
export const ListingComponent = (props: ListingComponentProps) => {

    /**
     * If an item width is specified, they will be aligned with that exact size.
     */
    const useAbsSizing = props.itemWidth != null
    const gridStyle = useAbsSizing ? { gridTemplateColumns: `repeat(auto-fit, ${props.itemWidth}px)` } : {}
    const itemStyle = useAbsSizing ? { width: `${props.itemWidth}px` } : {}

    return (
        <div className="ListingComponent">
            <h3 className="title">{props.title}</h3>
            <div className={classes("items justify-content-around justify-content-md-between", [props.controllingClass!, !useAbsSizing])} style={gridStyle}>
                {props.items.map((item) => (
                    <div key={item.key} className={classes("item", [props.controllingClass!, !useAbsSizing])} style={itemStyle}>
                        <img className="image" src={item.image} alt={item.title} style={{aspectRatio: `${props.aspectRatio}`}}/>
                        <span className="title">{item.title}</span>
                        <span className="subtitle">{item.subtitle}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ListingComponent;
