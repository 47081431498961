import React, {useContext, useEffect, useState} from 'react'
import './DropdownDate.scss'
import {DateTimeChooser} from "../date_time_chooser/DateTimeChooser";
import SearchDateContext from "../../../contexts/SearchDate";
import {prettyFormatDate} from "../../../logic/date_utils";
import {usePopper} from "react-popper";
import {classes} from "../../../logic/react_utility";

/**
 * The properties for {@link DropdownDate}.
 */
interface DropdownDateProps {
    className: string
}

/**
 * A dropdown version of {@link DateTimeChooser}.
 */
export const DropdownDate = ({className}: DropdownDateProps) => {
    const {date, setDate} = useContext(SearchDateContext)

    /**
     * The element being used as a root for the dropdown.
     */
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);

    /**
     * The {@link DateTimeChooser} element to be popped out/dropped down.
     */
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

    /**
     * If the dropdown is visible.
     */
    const [visible, setVisible] = useState<boolean>(false);

    /**
     * The created popper fields.
     *
     * @param styles     The styles to be applied to the popup element
     * @param attributes The attributes to be applied to the popup element
     * @param update     A function to update the popup element
     */
    const {styles, attributes, update} = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {name: 'preventOverflow', enabled: true}
        ]
    });

    /**
     * Invokes a {@link update} when visibility is changed. This is so the popup element has a valid initial state when
     * becoming visible.
     */
    useEffect(() => {
        update?.call(this)
    }, [visible])

    return (
        <div className={'DropdownDate ' + className} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
            <div ref={setReferenceElement} className={classes('handle form-control underline-input', ['empty', date == undefined])}>
                {date == undefined ? 'Select a date' : prettyFormatDate(date)}
            </div>
            {visible &&
                <DateTimeChooser date={date ?? new Date()} onChange={setDate} ref={setPopperElement} style={styles.popper} {...attributes.popper}/>
            }
        </div>
    );
}

/**
 * Default properties for {@link DropdownDateProps}.
 */
DropdownDate.defaultProps = {
    className: ''
}
