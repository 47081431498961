import React from 'react';
import './AdminPanel.scss';
import {Link} from "react-router-dom";

/**
 * The admin panel of the website, containing links to admin-specific things.
 */
export const Admin = () => {
    return (
        <div className="Admin">
            <h2>Admin Panel</h2>

            <Link to="/admin/schedule">Edit Schedule</Link><br/>
            <Link to="/admin/top_songs">Edit Top Songs</Link><br/>
            <Link to="/admin/users">Users</Link><br/>
            <Link to="/admin/staff">Manage Staff</Link><br/>
            <Link to="/admin/system_post">Edit System Post</Link><br/>
            <Link to="/admin/music_requests">Manage Music Requests</Link><br/>
            <Link to="/admin/shows">Manage Shows</Link>
        </div>
    );
}
