import {createContext} from "react";

/**
 * The state held by the {@link SearchDateContext} to provide a date.
 */
export type SearchDateState = {
    /**
     * The date being held.
     */
    date: Date | undefined,

    /**
     * Sets the date in the state.
     *
     * @param date The date to set
     */
    setDate: (date: Date | undefined) => void
}

/**
 * A context to provide a {@link Date} to nested components setting/reading from a date. This is mostly used in
 * searching.
 */
const SearchDateContext = createContext<SearchDateState>({
    date: undefined,
    setDate: _ => {}
})

export default SearchDateContext
