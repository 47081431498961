import React, {useContext, useEffect, useState} from 'react';
import './OnAir.scss';
import '../Sidebar.scss'
import {ArtworkDisplay} from "../../portable/artwork_display/ArtworkDisplay";
import TrackMultiReceiverContext from "../../../contexts/TrackMultiReceiver";
import {Link} from "react-router-dom";
import {Track} from "@witr-radio/music-logger-service";

/**
 * A component to display what is currently playing on the station.
 */
export const OnAir = () => {
    const trackMultiReceiver = useContext(TrackMultiReceiverContext)

    /**
     * The ID of the {@link trackMultiReceiver} so it can be unsubscribed to on unmount.
     */
    const [listenerId, setListenerId] = useState<number>(-1)

    /**
     * The currently playing track.
     */
    const [currentTrack, setCurrentTrack] = useState<Track | undefined>(undefined)

    /**
     * Starts listening to tracks playing.
     */
    useEffect(() => {
        // on mount
        setCurrentTrack(trackMultiReceiver.currentTrack)

        setListenerId(trackMultiReceiver.addListener(trackBroadcast => setCurrentTrack(trackBroadcast.track)))

        return () => {
            // on unmount
            trackMultiReceiver.removeListener(listenerId)
        }
    }, [])

    return (
        <div className="OnAir sidebar-component">
            <h3 className="title">On Air</h3>
            <ArtworkDisplay alt="Artwork" image={currentTrack?.getAlbumArt() ?? '/images/static/default_album.png'} primaryText={currentTrack?.title ?? '-'} secondaryText={currentTrack?.artist ?? ''}/>
            <div className="logger-box">
                <span>Missed a song name?</span>
                <Link to="/songs"><span>Check here</span> <span className="material-symbols-outlined inline-icon">arrow_forward</span></Link>
            </div>
        </div>
    );
}
