import React, {useContext, useState} from 'react'
import './Login.scss'
import useForm from "../../logic/useForm";
import {Button} from "../button/Button";
import {RequestHandlerContext} from "../../contexts/RequestHandler";
import {Result} from "../../logic/requests";
import { Link } from 'react-router-dom';

/**
 * The inputs that are mapped to the form by an input's name. These are for logging in.
 * This is initialized by {@link useForm}.
 */
type FormInputs = {
    /**
     * The email of the user.
     */
    email: string

    /**
     * The user's password.
     */
    password: string

    /**
     * The 2FA code, if present
     */
    toptCode: string
}

/**
 * A login page that sends back the requested Authentication token to the caller of this.
 */
export const Login = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The result status of the login request.
     */
    const [result, setResult] = useState(Result.Waiting)

    /**
     * A hook for processing form data.
     */
    const {handleSubmit} = useForm<FormInputs>()

    /**
     * Requests to log in with the credentials in the form {@link inputs}. If a result status is
     * {@link Result.Success}, the user will be redirected away. Otherwise, the {@link result} is set, displaying a
     * message to the user.
     *
     * @param inputs The form inputs of the page
     */
    const processSubmit = async (inputs: FormInputs) => {
        let status = await requestHandler.requestLogin(inputs.email, inputs.password, inputs.toptCode)
        if (status != Result.Success) {
            setResult(status)
        }
    }

    return (
        <div className="Login d-flex flex-row justify-content-center">
            <form className="col-8 col-md-4 col-xxl-2 my-5" onSubmit={e => handleSubmit(e, processSubmit)}>
                <div className="mb-4">
                    <input type="email" name="email" placeholder="Email address" className="form-control underline-input"/>
                </div>

                <div className="mb-4">
                    <input type="password" name="password" placeholder="Password" autoComplete="current-password" className="form-control underline-input"/>
                </div>

                <div className="mb-4">
                    <input type="text" name="toptCode" inputMode="numeric" pattern="[0-9]*" maxLength={6} autoComplete="one-time-code" placeholder="6 Digit Code" className="form-control underline-input"/>
                </div>

                <Button submit={true} className="mx-auto mb-3">Log In</Button>

                <div className="mb-4">
                    <Link className="text-muted" to="/forgot_password">Forgot Password?</Link>
                </div>

                {(result == Result.Unexpected || result == Result.InternalError) && <p className="text-danger mt-3">
                    An unexpected error occurred while logging in
                </p>}

                {result == Result.Unauthorized && <p className="text-danger mt-3">
                    Invalid credentials or 2FA Code
                </p>}
            </form>
        </div>
    )
}