import React from 'react'
import './WeeklyChart.scss'

/**
 * The possible groups a track may be a part of.
 */
enum Group {
    Feature = 'Feature',
    NewBin = 'New Bin'
}

/**
 * A representation of the Tracks that can be aired.
 */
class Track {
    /**
     * The artist of the track
     */
    artist: string

    /**
     * The name of the track
     */
    name: string

    /**
     * The group this track is a part of
     */
    group: Group

    /**
     * How many times the track has been played in the last week
     */
    count: number

    constructor(artist: string, name: string, group: Group, count: number) {
        this.artist = artist;
        this.name = name;
        this.group = group;
        this.count = count;
    }
}

// Temporary track data
const tracks: Track[] = [
    new Track('Kizo, Wac Woja, BeMelo', 'Nasze lato', Group.Feature, 18),
    new Track('Gedz, Sokos, 808Bros', 'Kosmita', Group.Feature, 16),
    new Track('Kizo, Trill Pem', 'Fitness', Group.NewBin, 11),
    new Track('be vis', 'Daleko stąd', Group.Feature, 6),
    new Track('Wiatr, Kobik', 'Ikigai', Group.Feature, 3),
]

/**
 * A chart of all songs played in the last week, and their frequency.
 */
export const WeeklyChart = () => {
    return (
        <div className="WeeklyChart">
            <h2>Weekly Charts</h2>

            <table className="chart">
                <thead>
                    <tr>
                        <th>Artist</th>
                        <th>Track</th>
                        <th>Group</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    {tracks.map(track => <tr>
                        <td>{track.artist}</td>
                        <td>{track.name}</td>
                        <td>{track.group}</td>
                        <td>{track.count}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}