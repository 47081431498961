/**
 * Defines the types for what the script below loads in.
 */
declare global {
    interface Window {
        grecaptcha: ReCaptcha
        captchaOnLoad: () => void
    }
}

/**
 * Simplified version of the `window.grecaptcha` types.
 */
interface ReCaptcha {
    /**
     * Defines a callback invoked when the CAPTCHA library is loaded.
     */
    ready: (callback: () => void) => void

    /**
     * Executes the CAPTCHA checking, returning its token to be verified.
     *
     * @param siteKey The reCAPTCHA site key
     * @param options The options of the execution
     */
    execute: (siteKey: string, options: {action: string}) => Promise<string>
}

/**
 * Loads the reCAPTCHA script.
 */
export function loadCaptchaScript(): void {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`
    script.async = true
    script.defer = true

    document.body.appendChild(script)
}

/**
 * Executes reCAPTCHA with the correct key and options, returning the resulting token.
 */
export function executeCaptcha(): Promise<string> {
    return window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY!, {action: 'submit'})
}