import React, {useContext, useEffect, useState} from 'react';
import './Home.scss';
import {SpotlightedPost} from "./spotligted_post/SpotlightedPost";
import {Sidebar} from "../sidebar/Sidebar";
import {ListingComponent, ListingItem} from "./listing_component/ListingComponent";
import {DetailedPost, Paginated, ShowDescription} from "../../logic/objects";
import RequestHandlerContext from "../../contexts/RequestHandler";

/**
 * The index page of the website.
 */
export const Home = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The post being featured.
     */
    const [spotlightedPost, setSpotlightedPost] = useState<DetailedPost>()

    /**
     * Shows being listed
     */
    const [shows, setShows] = useState<ListingItem[]>([])

    /**
     * Initializes the featured post and show listing.
     */
    useEffect(() => {
        requestFeaturedPost()

        listShows()
    }, [])

    /**
     * Requests and sets the {@link spotlightedPost}.
     */
    const requestFeaturedPost = (): void => {
        requestHandler.request('/api/show/post/featured')
            .then(async res => {
                let json = await res.json()
                setSpotlightedPost(json as DetailedPost)
            })
    }

    /**
     * Lists the available shows.
     */
    const listShows = (): void => {
        requestHandler.request('/api/show/list?count=20&page=0')
            .then(async res => {
                let json = await res.json()
                let paged = json as Paginated<ShowDescription>

                setShows(paged.items.map<ListingItem>((show, i) => {
                    return {
                        key: `${show.id}-${i}`,
                        image: show.image,
                        title: show.name,
                        subtitle: show.time
                    }
                }))
            })
    }

    return (
        <div className="Home d-flex flex-column flex-lg-row">
            <Sidebar className="order-1 order-lg-0 mt-5 mt-lg-0 px-2 pe-xl-4 col-lg-4 col-xl-3 col-xxl-2"/>
            <div className="Body order-0 order-lg-1 col-lg-8 col-xl-9 col-xxl-10 ">
                {spotlightedPost != undefined &&
                    <div className="row col-12 mx-0">
                        <SpotlightedPost post={spotlightedPost}/>
                    </div>}

                <ListingComponent aspectRatio={1} controllingClass='show-list' title='Featured Shows' items={shows}/>
            </div>
        </div>
    );
}
