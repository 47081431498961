import React, {useState} from 'react'
import './Bible.scss'
import useForm from "../../../logic/useForm";

/**
 * A representation of song search results.
 */
class SearchResult {
    /**
     * The ID of the track
     */
    id: number

    /**
     * The artist of the track
     */
    artist: string

    /**
     * The album of the track
     */
    album: string

    constructor(id: number, artist: string, album: string) {
        this.id = id;
        this.artist = artist;
        this.album = album;
    }
}

// Placeholder search results
const tempResults: SearchResult[] = [new SearchResult(11, 'Wiatr', 'Koktajl Mixtape'), new SearchResult(1, 'Deemz, Sobel', 'Patologiczny Zew'), new SearchResult(3, 'be vis', 'duch czasu')]

/**
 * A search page for the vinyl library.
 */
export const Bible = () => {

    /**
     * The form data types.
     */
    type FormInputs = {
        artist: string
        album: string
        id: string
    }

    const { handleSubmit } = useForm<FormInputs>()

    const [result, setResult] = useState<SearchResult[]>([]);

    const processSubmit = async (inputs: FormInputs) => {
        console.log(`Searching: ${inputs.artist} - ${inputs.album} - ${inputs.id}`);
        setResult(tempResults)
    }

    return (
        <div className="Bible">
            <form onSubmit={e => handleSubmit(e, processSubmit)}>
                <label>
                    <p>Artist</p>
                    <input type="text" name="artist"/>
                </label>
                <label>
                    <p>Album</p>
                    <input type="text" name="album"/>
                </label>
                <label>
                    <p>Id</p>
                    <input type="text" name="id"/>
                </label>
                <br/>
                <button className="search" type="submit">Search</button>
            </form>
            {result.length > 0 && <div className="results">
                <h4>{result.length} Result{result.length != 1 ? 's' : ''}</h4>

                <table>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Artist</th>
                        <th>Album</th>
                    </tr>
                    </thead>
                    <tbody>
                    {result.map(track => <tr key={track.id}>
                        <td>{track.id}</td>
                        <td>{track.artist}</td>
                        <td>{track.album}</td>
                    </tr>)}
                    </tbody>
                </table>
            </div>}
        </div>
    )
}
