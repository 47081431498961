import './UserSelector.scss'
import {Role} from "../../../logic/self";
import {getFullName, User} from "../../../logic/objects";
import RequestHandlerContext from "../../../contexts/RequestHandler";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {classes, State} from "../../../logic/react_utility";

interface UserSelectorProps {

    /**
     * The name used in forms, so they can be processed through the DOM. Users will be accessible through an invisible
     * {@code <select>} element with the same name as this.
     */
    name?: string | undefined
    
    /**
     * The users selected by this element.
     */
    selectedUsers: User[]

    /**
     * Sets the users selected by this element, for an externally-manages state. This should update {@link selectedUsers}.
     */
    setSelectedUsers: (setFunction: ((old: User[]) => User[])) => void

    /**
     * The maximum selected users allowed. If undefined, there will be no limit.
     */
    maxSelected?: number | undefined

    /**
     * If set, all users available must have at least this role or above.
     */
    minimumRole?: Role | undefined
}

/**
 * A component to select one or more users by searching their name. Autocomplete is supported.
 */
export const UserSelector = ({name, selectedUsers, setSelectedUsers, maxSelected, minimumRole}: UserSelectorProps) => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * If the inner input is focused, to simulate focusing the wrapper element.
     */
    const [focused, setFocused] = useState<boolean>(false)

    const [predictedUser, setPredictedUser] = useState<User | undefined>()

    const [inputElement, setInputElement] = useState<HTMLInputElement | null>(null)

    /**
     * The users that may be selected.
     */
    const [users, setUsers] = useState<User[]>([])

    useEffect(() => {
        refreshUsers()
    }, [])

    /**
     * Sets the {@link users} list to all available users.
     */
    const refreshUsers = (): void => {
        let roleParam = minimumRole == undefined ? '' : `&minimumRole=${minimumRole.name}`
        requestHandler.request(`/api/user/list?count=100&page=0${roleParam}`).then(async res => {
            let json = await res.json()
            setUsers(json.items as User[])
        });
    }

    /**
     * Handles input change to predict the user being typed in. If a match is found, capitalization is corrected to the
     * matching user's name.
     *
     * @param text The text the user is typing
     */
    const onChange = (text: string): void => {
        if (text.length == 0) {
            setPredictedUser(undefined)
            return
        }

        if (maxSelected != undefined && selectedUsers.length >= maxSelected) {
            return
        }

        let predicted = users.filter(user => selectedUsers.findIndex(selectedUser => selectedUser.id == user.id) == -1)
            .find(user => getFullName(user).toLowerCase().startsWith(text.toLowerCase()))
        setPredictedUser(predicted)

        if (predicted != undefined && inputElement != undefined) {
            inputElement.value = getFullName(predicted).substring(0, inputElement.value.length)
        }
    }

    /**
     * Handles tab complete and backspace to delete users.
     *
     * @param e The keyboard event of the input element
     */
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key == 'Tab') {
            e.preventDefault()

            if (predictedUser != undefined) {
                if (inputElement != undefined) {
                    inputElement.value = ''
                }

                setSelectedUsers(old => [...old, predictedUser])
                setPredictedUser(undefined)
            }
        } else if (e.key == 'Backspace' && (inputElement?.value.length ?? 0) == 0) {
            if (selectedUsers.length > 0) {
                removeSelectedUser(selectedUsers[selectedUsers.length - 1])
            }
        }
    }

    /**
     * Removes the given user using {@link setSelectedUsers}.
     *
     * @param user The user to remove
     */
    const removeSelectedUser = (user: User): void => {
        setSelectedUsers(old => old.filter(oldUser => oldUser.id != user.id))
    }

    /**
     * Gets the full name of {@link predictedUser}. If there is no {@link predictedUser}, `undefined` is returned.
     */
    const getPredictedName = (): string | undefined => {
        if (predictedUser == undefined) {
            return undefined
        }

        return getFullName(predictedUser)
    }

    return (
        <div className="UserSelector">
            <div className={classes('form-control', ['focused', focused])} placeholder="Default input" aria-label="default input example">
                {selectedUsers.map(user =>
                    <span key={user.id} className="badge fw-normal text-bg-primary p-2 me-1">{getFullName(user)}
                        <span className="material-symbols-outlined inline-icon delete ms-1" onClick={() => removeSelectedUser(user)}>close</span></span>)}
                <span className="autocomplete-wrapper" data-autofill={getPredictedName()}></span>
                <input ref={setInputElement} className="remaining-input" type="text" onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} onChange={e => onChange(e.target.value)} onKeyDown={onKeyDown}/>
            </div>

            {name != undefined &&
                <select name={name} className="d-none" multiple>
                    {selectedUsers.map(user => <option key={user.id} value={user.id} selected></option>)}
                </select>}
        </div>
    )
}

/**
 * Properties for the DOM-specific user selector. The same as {@link UserSelectorProps} but without external state
 * management.
 */
interface DOMUserSelectorProps {

    /**
     * The name used in forms, so they can be processed through the DOM. Users will be accessible through an invisible
     * `<select>` element with the same name as this.
     */
    name: string

    /**
     * The maximum selected users allowed. If undefined, there will be no limit.
     */
    maxSelected?: number | undefined

    /**
     * If set, all users available must have at least this role or above.
     */
    minimumRole?: Role | undefined
}

/**
 * A {@link UserSelector} but with internally managed state. All selected users are put in a hidden `<select>`
 * element with the same name as the `name` property to be read by forms.
 */
export const DOMUserSelector = ({name, maxSelected, minimumRole}: DOMUserSelectorProps) => {
    const [users, setUsers] = useState<User[]>([])
    
    return <UserSelector name={name} selectedUsers={users} setSelectedUsers={setFunction => setUsers(setFunction(users))} maxSelected={maxSelected} minimumRole={minimumRole}/>
}
