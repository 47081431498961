import React from 'react'
import './Button.scss'
import {classes} from "../../logic/react_utility";

/**
 * The possible button actions.
 */
type ButtonType = 'submit' | 'reset' | 'button' | undefined

/**
 * The possible sizes of a button.
 */
type ButtonSize = 'small' | 'medium' | 'large'

/**
 * The properties for {@link Button}.
 */
interface ButtonProps {
    /**
     * The children of the button.
     */
    children: React.ReactNode

    /**
     * The callback invoked when the button is clicked.
     */
    onClick?: () => void

    /**
     * The optional size of the button. By default, this is `medium`.
     */
    size?: ButtonSize

    /**
     * Any class names to add to the button.
     */
    className?: string

    /**
     * If the button should be of type `submit`.
     */
    submit: boolean

    /**
     * If the button is inactive. If so, it will be outlined. Otherwise, it will be a solid color.
     */
    inactive: boolean

    /**
     * If the button is disabled, meaning it is grayed out and unclickable.
     */
    disabled: boolean
}

const SIZE_CLASSES = new Map<ButtonSize, string>([
    ['small', 'btn-sm'],
    ['medium', ''],
    ['large', 'btn-lg']
])

/**
 * A button component, styled to the WITR theme.
 */
export const Button = (props: ButtonProps) => {
    let sizeClass = SIZE_CLASSES.get(props.size ?? 'medium') ?? ''
    let type: ButtonType = props.submit ? 'submit' : undefined
    return (
        <button type={type} className={classes('Button btn', sizeClass, props.className ?? '', ['btn-primary', !props.inactive], ['btn-outline-primary', props.inactive])} onClick={props.onClick} disabled={props.disabled}>
            {props.children}
        </button>
    )
}

/**
 * The default properties for {@link ButtonProps}
 */
Button.defaultProps = {
    submit: false,
    inactive: false,
    disabled: false
}
