import './SpotlightedPost.scss';
import {combineNames, DetailedPost} from "../../../logic/objects";
import DOMPurify from "dompurify";
import {marked} from "marked";
import moment from "moment";
import {RawHtml} from "../../portable/raw_html/RawHtml";
import {getDisplayName} from "../../../logic/utility";

/**
 * The properties for {@link SpotlightedPost}.
 */
interface SpotlightedPostProps {
    /**
     * The post being spotlighted.
     */
    post: DetailedPost
}

/**
 * A component to display a single post preview on the homepage.
 */
export const SpotlightedPost = (props: SpotlightedPostProps) => {
    let post = props.post

    if (post?.show == undefined ?? true) {
        return <p>No spotlighted post</p>
    }

    let cleanHtmlPost = DOMPurify.sanitize(marked.parse(post.content))

    return (
        <div className="SpotlightedPost col-12 d-flex flex-column flex-xl-row px-0">
            <div className="spotlight-content col-12 d-flex flex-column pt-2 pt-xl-0 ps-xl-4">

                <div className="summary">
                    <img className="spotlight-image col-12 col-xl-5 me-0 me-xl-4 mb-2 mb-xl-0" src={post.image} alt="Spotlighted image"/>

                    <div className="post-meta mb-0 mb-xl-2">
                        <span className="source">{post.show.name}</span>
                        <h3 className="title">{post.title}</h3>
                        <p className="author mb-3">{post.authors.length != 0 ? combineNames(post.authors) : '-'} &bull; {moment(post.posted).format('LLL')}</p>
                    </div>

                    <RawHtml html={cleanHtmlPost}/>
                </div>
            </div>
        </div>
    );
}
