import React, {ChangeEvent, CSSProperties, ForwardedRef, useState} from 'react'
import {Calendar} from "react-date-range";
import './DateTimeChooser.scss'
import {Button} from "../../button/Button";
import useTimeController from "@witr-radio/music-logger-service";

/**
 * The properties for {@link DateTimeChooser}.
 */
interface DateTimeChooserProps {
    /**
     * The initial date of the chooser.
     */
    date: Date

    /**
     * Invoked when the date or time in the chooser has been changed.
     *
     * @param date The new date
     */
    onChange: (date: Date) => void

    /**
     * The style to apply to the {@link DateTimeChooser}.
     */
    style: CSSProperties | undefined
}

/**
 * A component to select a date and time.
 */
export const DateTimeChooser = React.forwardRef((props: DateTimeChooserProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        handleDate,
        updateDate,
        handleHour,
        handleMinute,
        handleAmPm,
        defaultHour,
        calendarDate,
        am
    } = useTimeController({date: props.date, onChange: props.onChange})

    return (
        <div className="DateTimeChooser" ref={ref} style={props.style}>
            <Calendar className="calendar" date={calendarDate} onChange={handleDate} showDateDisplay={false}/>
            <div className="time-wrapper">
                <div className="px-3 time-selector">
                    <input type="number" className="form-control underline-input" defaultValue={defaultHour()} onChange={handleHour} min="1" max="12"/>
                    <span className="colon">:</span>
                    <input type="number" className="form-control underline-input" defaultValue={props.date.getMinutes()} onChange={handleMinute} min="0" max="59"/>
                    <div className="buttons">
                        <Button onClick={() => handleAmPm(true)} size="small" inactive={!am}>AM</Button>
                        <Button onClick={() => handleAmPm(false)} size="small" inactive={am}>PM</Button>
                    </div>
                </div>
            </div>
        </div>
    )
})
