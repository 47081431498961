import React, {useContext, useEffect, useState} from 'react'
import './PasswordReset.scss'
import useForm from "../../logic/useForm";
import {Button} from "../button/Button";
import {RequestHandlerContext} from "../../contexts/RequestHandler";
import {Result, statusToResult} from "../../logic/requests";
import {Link} from 'react-router-dom';

/**
 * The inputs that are mapped to the form by an input's name. These are for resetting a password.
 * This is initialized by {@link useForm}.
 */
type FormInputs = {
    /**
     * The new password.
     */
    password: string
}

/**
 * The password reset page. This requires the `resetToken` query parameter, given by a password reset email.
 */
export const PasswordReset = () => {
    const requestHandler = useContext(RequestHandlerContext)

    /**
     * The result status of the reset request.
     */
    const [result, setResult] = useState(Result.Waiting)

    /**
     * If the password inputs match.
     */
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true)

    /**
     * The first password.
     */
    const [firstPassword, setFirstPassword] = useState<string>('')

    /**
     * The second password.
     */
    const [secondPassword, setSecondPassword] = useState<string>('')

    /**
     * The query parameters of the current window.
     */
    const queryParams = new URLSearchParams(window.location.search)

    /**
     * The reset token, provided by the backend. This is provided as an identifier for the request already started in
     * the backend, and is unusable after a single use.
     */
    const token = queryParams.get('resetToken') ?? ''

    /**
     * A hook for processing form data.
     */
    const {handleSubmit} = useForm<FormInputs>()

    /**
     * Resets the user's password with the {@link token}. The request result is set to {@link result}.
     *
     * @param inputs The form inputs of the page
     */
    const processSubmit = async (inputs: FormInputs): Promise<void> => {
        let status = await requestHandler.request('/api/auth/reset_password', {
            method: 'POST', body: JSON.stringify({
                password: inputs.password,
                resetToken: token,
            })
        })

        setResult(statusToResult(status.status))
    }

    /**
     * When either password input has changed, {@link passwordsMatch} is updated.
     */
    useEffect(() => {
        setPasswordsMatch(firstPassword == secondPassword)
    }, [firstPassword, secondPassword])

    return (
        <div className="Login d-flex flex-row justify-content-center">
            <form className="col-8 col-md-4 col-xxl-2 my-5" onSubmit={e => handleSubmit(e, processSubmit)}>
                <div className="mb-4">
                    <input type="password" name="password" placeholder="Password" autoComplete="new-password" className="form-control underline-input" onChange={e => setFirstPassword(e.target.value)}/>
                </div>

                <div className="mb-4">
                    <input type="password" name="confirmPassword" placeholder="Confirm Password" autoComplete="new-password" className="form-control underline-input" onChange={e => setSecondPassword(e.target.value)}/>
                </div>

                <Button submit={true} className="mx-auto mb-3">Reset Password</Button>

                {!passwordsMatch && <p className="text-danger mt-3">
                    Passwords must match
                </p>}

                {result == Result.Success && <p className="text-success mt-3">
                    Password reset successfully! Please <Link to="/login">log in</Link> to continue
                </p>}

                {(result == Result.Unexpected || result == Result.InternalError) && <p className="text-danger mt-3">
                    An unexpected error occurred while logging in
                </p>}

                {result == Result.Unauthorized && <p className="text-danger mt-3">
                    Invalid credentials or 2FA Code
                </p>}
            </form>
        </div>
    )
}